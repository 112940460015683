import React, { useEffect, useState } from 'react'
import './style.css'
import Weathercard from './Weathercard';

const Weather = () => {
 const[searchValue,setSearchValue]=useState("Delhi");
 const[tempInfo,setTempinfo]=useState({});

 const getWeatherInfo= async()=>{
    try{
           let urls=`https://api.openweathermap.org/data/2.5/weather?q=${searchValue}&units=metric&appid=6648f41051424600f21577328ac67268`;
           let res=await fetch(urls);
           let data=await res.json();
           const {temp,humidity,pressure}=data.main;
           const {main:weathermood}=data.weather[0];
           const{name}=data;
           const{speed}=data.wind;
           const {country,sunset}=data.sys;
           const mynewweatherinfo={
            temp,humidity,pressure,
            weathermood,name,country,sunset,speed
           };
           setTempinfo(mynewweatherinfo);

    }catch(error){
        console.log(error);
    }

 }
 useEffect(()=>{getWeatherInfo();},[]);
  return (
<>

    <div className="wrap">
   
        <div className="search">
            <input type="search"  placeholder='search....' autoFocus id='search' className='searchTerm' value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}  />
            <button className='searchButton' type='button' onClick={getWeatherInfo}>Search</button>
        </div>
    </div>
   <Weathercard tempInfo={tempInfo}/>

</>
  )
}

export default Weather
